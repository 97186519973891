import { useCallback, useEffect, useMemo } from 'react'
import { FiPlus } from 'react-icons/fi'

import { useQuery } from '@tanstack/react-query'

import AlertInfo from '~/components/AlertInfo'
import { Button } from '~/components/Buttons'
import HeaderPages from '~/components/HeaderPages'
import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import PageTitle from '~/components/PageTitle'
import Table from '~/components/Table'
import { getWhiteLabelPublisherId, history } from '~/helpers'
import { handleSortDirection } from '~/helpers/sortData'
import { useAppInfo } from '~/hooks/useAppInfo'
import { useErrorApiAlert } from '~/hooks/useErrorApiAlert'
import useQueryParams from '~/hooks/useQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { listAudiencesService } from '~/modules/retailMedia/services/audience'

import AudienceTabs from '../elements/AudienceTabs'
import { schameAudiencesPublisher } from './schema'

const AudiencesDashboard: React.FC = () => {
  const { t } = useAPPTranslation()

  /**
   * Handle request
   */
  const searchParams = useQueryParams()
  const name = searchParams.get('name')
  const page = Number(searchParams.get('page')) || 1
  const quantity = Number(searchParams.get('quantity')) || 50
  const publisherId =
    (searchParams.get('rmid') as BrandedType<string, 'PublisherId'>) || null
  const status = searchParams.get('status') as AudienceStatus
  const billing_type = searchParams.get('billing_type') as AudienceBillingType
  const visibility = searchParams.get('visibility') as 'public' | 'private'
  const sort_direction = searchParams.get('sort_direction') as SortDirection
  const sort_key = searchParams.get('sort_key')

  const { isWhiteLabel } = useAppInfo()

  const selectedPublisherId = useMemo(
    () => (isWhiteLabel ? getWhiteLabelPublisherId() : publisherId),
    [isWhiteLabel, publisherId]
  )

  const params = useMemo(() => {
    const data: ListAudienceQueryParams = {
      page,
      quantity,
      name,
      publisher_id: selectedPublisherId,
      status,
      billing_type,
      order_direction: sort_direction,
      order_by: sort_key
    }

    if (visibility) {
      data.is_global = visibility === 'public'
    }

    return data
  }, [
    billing_type,
    name,
    sort_direction,
    sort_key,
    page,
    quantity,
    selectedPublisherId,
    status,
    visibility
  ])

  const { isPending, isFetching, data, error } = useQuery({
    queryKey: ['list-audience', params],
    queryFn: async () => {
      const { data } = await listAudiencesService(params)

      return data
    }
  })

  const [AlertOnError, setError] = useErrorApiAlert({
    title: t('c:messages.errorOccurred.title')
  })

  useEffect(() => {
    if (error) setError(error)
  }, [error, setError])

  /**
   * Handle Pagination
   */
  const handlePagination = useCallback(
    (value: number) => {
      searchParams.set('page', String(value))
      history.push({ search: searchParams.toString() })
    },
    [searchParams]
  )

  const handleItemsPerPageChange = useCallback(
    (value: { value: number }) => {
      searchParams.set('quantity', String(value.value))
      searchParams.set('page', '1')
      history.push({ search: searchParams.toString() })
    },
    [searchParams]
  )
  /**
   * Sort list
   */
  const handleSortList = useCallback(
    (data: OnSortProps) => {
      const selectedKey = data.key
      const sortInitialDirection = data?.sortInitialDirection

      const response = handleSortDirection({
        selectedKey,
        currentSortDirection: sort_direction,
        currentSortKey: sort_key,
        sortInitialDirection
      })

      searchParams.set('sort_direction', response.sortDirection)
      searchParams.set('sort_key', response.sortKey)

      history.push({ search: searchParams.toString() })
    },
    [searchParams, sort_direction, sort_key]
  )

  return (
    <>
      <PageTitle title={t('rm:audience.home.pageTitle')} />

      <HeaderPages
        title={t('rm:audience.home.pageTitle')}
        subtitle={t('rm:audience.home.pageSubtitle')}
        sideRightComponent={
          <Button text="Nova audiência" iconLeft={<FiPlus />} />
        }
      />

      <AudienceTabs />

      <section>{`<AudiencesFilters />`}</section>

      <Loading status={isPending} />

      <Loading status={!isPending && isFetching} onlyLine />

      <AlertOnError />

      {!!data?.total && (
        <Table
          schema={schameAudiencesPublisher}
          data={data?.data}
          sideBorder
          freeze="first-row-and-column"
          // sort settings
          activeSortDirection={sort_direction}
          activeSortKey={sort_key}
          onSort={handleSortList}
        />
      )}

      {data?.total === 0 && data && (
        <AlertInfo template="warning" text={t('c:table.emptyFiltered')} />
      )}

      <ListPagination
        total={data?.total}
        label={t('rm:audience', { count: data?.total })}
        currentPage={page}
        itemsPerPage={quantity}
        menuPlacement="top"
        onClickPagination={handlePagination}
        onItemsPerPageChange={handleItemsPerPageChange}
        customOptions={[5, 10, 20, 50]}
      />
    </>
  )
}

export default AudiencesDashboard

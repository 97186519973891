import { useCallback, useEffect } from 'react'
import { FiSave, FiX } from 'react-icons/fi'
import { toastr } from 'react-redux-toastr'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Form } from '@unform/web'

import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import PageTitle from '~/components/PageTitle'
import { Badge } from '~/components/UI/Badge'
import { FormGroup, Input, Label, Textarea } from '~/components/unform'
import { useErrorApiAlert } from '~/hooks/useErrorApiAlert'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import {
  getAudienceEnrichedService,
  updateAttributeAudienceService
} from '~/modules/retailMedia/services/audience-enriched'

type EditAudienceAttributeProps = Pick<AudienceEnrichedData, 'id'> & {
  onCancel: () => void
  // key da requisição para revalidar pós update
  listQueryKey: string[]
}

const EditAudienceAttribute: React.FC<EditAudienceAttributeProps> = ({
  id,
  onCancel,
  listQueryKey
}) => {
  const { t } = useAPPTranslation()
  const queryKey = ['get-audience-enriched', id]

  const { isPending, isFetching, data, error } = useQuery({
    queryKey,
    queryFn: async () => {
      const { data } = await getAudienceEnrichedService(id)

      return data
    }
  })

  /**
   * <Handle error>
   */

  const [AlertOnError, setError] = useErrorApiAlert({
    title: t('c:messages.errorOccurred.title')
  })

  useEffect(() => {
    if (error || (!isPending && data === null))
      setError(error || new Error('Data not found'))
  }, [data, error, isPending, setError])

  /**
   * </Handle error>
   */

  /**
   * <Handle PATCH request and collateral effects>
   */

  const queryClient = useQueryClient()

  const {
    mutate,
    isPending: isUpdatePending,
    error: updateError
  } = useMutation({
    mutationFn: updateAttributeAudienceService,
    onSuccess: response => {
      console.log('🚀 ~ response:', response)
      queryClient.invalidateQueries({
        queryKey: listQueryKey
      })

      queryClient.invalidateQueries({
        queryKey
      })

      toastr.success(
        t('rm:audience.update.toast.success.title'),
        t('rm:audience.update.toast.success.messageName', {
          name: data?.label || data?.key
        })
      )
    },
    onError: () => {
      toastr.error(
        t('common:messages.errorOccurred.title'),
        t('rm:audience.update.toast.error.message')
      )
    }
  })

  const [AlertOnUpdateError, setUpdateError] = useErrorApiAlert({
    title: t('c:messages.errorOccurred.title')
  })

  useEffect(() => {
    if (updateError) setUpdateError(updateError)
  }, [data, error, isPending, setError, setUpdateError, updateError])
  /**
   * </Handle PATCH request and collateral effects>
   */

  /**
   * <Handle form submit>
   */
  type AttributeFormData = {
    label: string
    description: string
  }

  const handleSubmit = useCallback(
    (formData: AttributeFormData) => {
      setUpdateError(null)

      mutate({
        id,
        body: { ...data, ...formData }
      })
    },
    [data, id, mutate, setUpdateError]
  )
  /**
   * </Handle form submit>
   */

  return (
    <>
      <PageTitle
        title={`${data ? `${data?.label || 'Sem nome definido'} [${data?.key}]` : t('common:actions.loading')} - ${t('rm:placements.edit.pageTitle')}`}
      />

      <section className="px-3 pb-3">
        <Loading status={isPending}>
          {t('common:actions.loadingData')}...
        </Loading>

        <AlertOnError />

        {!!data && (
          <>
            {/* @ts-expect-error: Form component does not have proper types */}
            <Form onSubmit={handleSubmit} initialData={data}>
              <Badge color="primary" className="mb-3" text={data.key} />

              <FormGroup className="mb-3">
                <Label htmlFor="label">Nome</Label>
                <Input id="label" name="label" />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="description">Descrição</Label>
                <Textarea
                  id="description"
                  name="description"
                  showLength
                  maxLength={200}
                />
              </FormGroup>

              <AlertOnUpdateError />

              <Loading status={isUpdatePending}>
                {t('common:actions.savingUpdates')}...
              </Loading>

              <FormGroup className="actionButtons">
                <Button
                  text={t('common:actions.cancel')}
                  iconLeft={<FiX />}
                  onClick={onCancel}
                  disabled={isUpdatePending}
                  template="transparentDanger"
                  className="me-auto px-0"
                  customWidth="auto"
                />

                <Button
                  text={t('common:actions.save')}
                  textLoading={`${t('common:actions.saving')}...`}
                  iconLeft={<FiSave />}
                  loading={isUpdatePending}
                  disabled={isUpdatePending}
                  template="success"
                  type="submit"
                />
              </FormGroup>
            </Form>
          </>
        )}
      </section>
    </>
  )
}

export default EditAudienceAttribute

import { FiAlertTriangle } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'

import { Badge } from '~/components/UI/Badge'

// add id as edit_id on current url and kepp current params
// return string to use in NavLink
const formatUrl = (id: BrandedType<number, 'AudienceEnrichedId'>) => {
  const url = new URL(window.location.href)
  url.searchParams.set('edit_id', String(id))
  return url.toString()
}

export const schameAudiencesPublisher: TableSchema<AudienceEnrichedData> = {
  key: {
    title: 'Atributos',
    render: data => (
      <NavLink to={formatUrl(data.id)}>
        <Badge size="small">{data.key}</Badge>
        <div className="name">
          {data.label ? (
            <strong>{data.label}</strong>
          ) : (
            <Badge
              template="warning"
              text="Sem nome definido"
              iconLeft={<FiAlertTriangle />}
            />
          )}
        </div>
        {data?.description && (
          <p className="fs-6 text-neutral-low-light m-0">{data.description}</p>
        )}
      </NavLink>
    )
  }
}

import { FiGlobe, FiLock } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import RangeSlider from '~/components/RangeSlider'
import Status from '~/components/Status'
import { Badge } from '~/components/UI/Badge'
import { formatMoneyV2, formatNumberWithK } from '~/helpers'
import i from '~/i18n'

type AudienceStatuses = {
  [key in AudienceStatus]: StatusData<AudienceStatus>
}
const audienceStatus: AudienceStatuses = {
  awaiting_approval: {
    type: 'warning',
    label: i.t(`rm:audience.status.awaiting_approval`)
  },
  awaiting_processing: {
    type: 'success_alert',
    label: i.t(`rm:audience.status.awaiting_processing`)
  },
  approved: {
    type: 'success',
    label: i.t(`rm:audience.status.approved`)
  },
  rejected: {
    type: 'danger',
    label: i.t(`rm:audience.status.rejected`)
  }
}

export const schameAudiencesPublisher: TableSchema<AudienceData> = {
  name: {
    title: 'Nome da audiência',
    render: data => (
      <Link to={`retail-media/audiences/view/${data.id}`}>{data.name}</Link>
    )
  },
  status: {
    title: 'Status',
    render: data => (
      <Status
        status={audienceStatus?.[data.status] || { label: data.status }}
      />
    )
  },
  reach_range: {
    title: 'Alcance pot.',
    render: data => (
      <div>
        <div className="value fs-5">
          {formatNumberWithK({ number: data?.estimated_audience })}
        </div>
        <RangeSlider
          min={0}
          max={100}
          value={data?.reach_range || 0}
          readOnly
          size="small"
        />
      </div>
    )
  },
  cost: {
    title: 'Custo',
    render: data => (
      <div>
        <div className={`fs-5 ${!data.price ? 'text-neutral-low-light' : ''}`}>
          {formatMoneyV2({ value: data.price })}{' '}
        </div>
        <Badge
          size="small"
          template={data.price ? 'warning' : 'success'}
          text={
            data.price
              ? i.t('rm:AudienceCard.text.audienceCost.fixedCost')
              : i.t('rm:AudienceCard.text.audienceCost.noCost')
          }
        />
      </div>
    )
  },
  visibility: {
    title: 'Visibilidade',
    render: data =>
      data.is_global ? (
        <Badge text="Global" iconLeft={<FiGlobe />} size="small" />
      ) : (
        <Badge text="Privado" iconLeft={<FiLock />} size="small" />
      )
  },
  campaignUsage: {
    title: 'Uso em campanhas',
    render: data => data?.campaign_usage || '-'
  }
}
